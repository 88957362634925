import { AxiosResponse } from 'axios'
import axios from '../instance'

export interface IParamsLeadList {
	offset: number
	limit: number
	search?: string,
	status?: LEAD_STATUS | null,
	leadType?: LEAD_TYPE | null,
}

export const getLeadList = async (params: IParamsLeadList): Promise<any> => {
	try {
		const { data } = await axios.get('/admin/lead', { params })
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export enum LEAD_TYPE {
	NOT_SHIPPING = 'NOT_SHIPPING',
	STOP_SHIPPING = 'STOP_SHIPPING',
}
  
export enum LEAD_STATUS {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	UN_FOLLOW = 'UN_FOLLOW',
  }

export interface ILeadUpdatePayload {
	leadId: string
	status?: LEAD_STATUS,
	remark?: string
}

export const leadUpdateApi = async (payload: ILeadUpdatePayload): Promise<any> => {
	try {
		const { data } = await axios.post('/admin/lead/update', payload)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}